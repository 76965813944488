




import ListsOptionsCC from '/src/class-components/settings/ListsOptionsCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    ListsOptionsCC
  }
})
export default class ListsOptions extends Vue {}
