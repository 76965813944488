var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "tabs-component",
        { attrs: { pills: "" } },
        [
          _c(
            "b-tab",
            {
              attrs: { active: "" },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("\n        Clearing Houses\n      ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("ClearingHousesCC")],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("\n        EHR Systems\n      ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("EHRsystemsCC")],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("\n        Modifiers\n      ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("ModifiersCC")],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("\n        Diagnosis\n      ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("DiagnosisCC")],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("\n        Contact Role Types\n      ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("ContactRoleTypesCC")],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("\n        POS codes\n      ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("POScodesCC")],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("\n        Dr. Specialties\n      ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("DrSpecialtiesCC")],
            1
          ),
          _c(
            "b-tab",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("\n        Insurance Plan\n      ")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("InsurancePlanCC")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }