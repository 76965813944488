var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          "do-save": true,
          "is-add-edit-sidbar": true,
          validate: true,
          fields: _vm.fields,
          "sidebar-open": _vm.modifiersSidebar,
          "is-data-from-model": false,
          "items-repo": _vm.modifiersRepo,
          "sidebar-data": _vm.selectedModifier,
          "copy-data": _vm.selectedModifierCopy,
        },
        on: {
          "update:sidebarOpen": function ($event) {
            _vm.modifiersSidebar = $event
          },
          "update:sidebar-open": function ($event) {
            _vm.modifiersSidebar = $event
          },
          "update:sidebarData": function ($event) {
            _vm.selectedModifier = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedModifier = $event
          },
          "update:copyData": function ($event) {
            _vm.selectedModifierCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.selectedModifierCopy = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(causesWriteOff)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(data.item.causesWriteOff ? "Yes" : "No") +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-container",
                  { staticClass: "px-2 mt-1" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _vm.selectedModifierCopy.id
                              ? _c("h4", [_vm._v("Edit Modifier")])
                              : _c("h4", [_vm._v("Add Modifier")]),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Modifier" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Modifier",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "upper",
                                                rawName: "v-upper",
                                              },
                                            ],
                                            attrs: {
                                              placeholder: "",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.selectedModifierCopy.code,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedModifierCopy,
                                                  "code",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedModifierCopy.code",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Description" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Description",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-textarea", {
                                            attrs: {
                                              placeholder: "",
                                              rows: "3",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.selectedModifierCopy
                                                  .description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedModifierCopy,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedModifierCopy.description",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Is justifiable write off?" } },
                              [
                                _c("validation-provider", {
                                  staticClass: "demo-inline-spacing pt-0",
                                  attrs: {
                                    name: "write-off",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "b-form-radio",
                                            {
                                              staticClass: "pt-0 mt-0",
                                              attrs: {
                                                name: "write-off",
                                                value: true,
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedModifierCopy
                                                    .causesWriteOff,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedModifierCopy,
                                                    "causesWriteOff",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedModifierCopy.causesWriteOff",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Yes\n                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-form-radio",
                                            {
                                              staticClass: "pt-0 mt-0",
                                              attrs: {
                                                name: "write-off",
                                                value: false,
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedModifierCopy
                                                    .causesWriteOff,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedModifierCopy,
                                                    "causesWriteOff",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedModifierCopy.causesWriteOff",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  No\n                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }