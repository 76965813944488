var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          "is-data-from-model": false,
          "sidebar-data": _vm.selectedClearingHouse,
          "copy-data": _vm.selectedClearingHouseCopy,
          "items-repo": _vm.clearingHouseRepo,
          "sidebar-open": _vm.sidebar,
          "is-add-edit-sidbar": true,
          fields: _vm.fields,
          "do-save": true,
          validate: true,
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.selectedClearingHouse = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedClearingHouse = $event
          },
          "update:copyData": function ($event) {
            _vm.selectedClearingHouseCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.selectedClearingHouseCopy = $event
          },
          "update:sidebarOpen": function ($event) {
            _vm.sidebar = $event
          },
          "update:sidebar-open": function ($event) {
            _vm.sidebar = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(emailWeb)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      data.item.email ? data.item.email.emailAddress : ""
                    ) +
                    "\n      "
                ),
                _c("br"),
                _vm._v("\n      " + _vm._s(data.item.website) + "\n    "),
              ]
            },
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-container",
                  { staticClass: "px-2 mt-1" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _vm.selectedClearingHouseCopy.id
                              ? _c("h4", [_vm._v("Edit Clearing House")])
                              : _c("h4", [_vm._v("Add Clearing House")]),
                            _c(
                              "b-form-group",
                              { staticClass: "mt-2", attrs: { label: "Name" } },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Name", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: "",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.selectedClearingHouseCopy
                                                  .name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedClearingHouseCopy,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedClearingHouseCopy.name",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Code" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Code",
                                    rules: "required|min:2|max:4",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "upper",
                                                rawName: "v-upper",
                                              },
                                            ],
                                            attrs: {
                                              placeholder: "",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.selectedClearingHouseCopy
                                                  .code,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedClearingHouseCopy,
                                                  "code",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedClearingHouseCopy.code",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c("email-phone-single", {
                              attrs: {
                                "source-email":
                                  _vm.selectedClearingHouseCopy.email,
                                "source-phone":
                                  _vm.selectedClearingHouseCopy.phone,
                              },
                              on: {
                                "update:sourceEmail": function ($event) {
                                  return _vm.$set(
                                    _vm.selectedClearingHouseCopy,
                                    "email",
                                    $event
                                  )
                                },
                                "update:source-email": function ($event) {
                                  return _vm.$set(
                                    _vm.selectedClearingHouseCopy,
                                    "email",
                                    $event
                                  )
                                },
                                "update:sourcePhone": function ($event) {
                                  return _vm.$set(
                                    _vm.selectedClearingHouseCopy,
                                    "phone",
                                    $event
                                  )
                                },
                                "update:source-phone": function ($event) {
                                  return _vm.$set(
                                    _vm.selectedClearingHouseCopy,
                                    "phone",
                                    $event
                                  )
                                },
                              },
                            }),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Website" } },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Website", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: "",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.selectedClearingHouseCopy
                                                  .website,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedClearingHouseCopy,
                                                  "website",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedClearingHouseCopy.website",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              { attrs: { label: "Status" } },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Status", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-select", {
                                            attrs: {
                                              options: [
                                                {
                                                  text: "Active",
                                                  value: "Active",
                                                },
                                                {
                                                  text: "Inactive",
                                                  value: "Inactive",
                                                },
                                              ],
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.selectedClearingHouseCopy
                                                  .status,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedClearingHouseCopy,
                                                  "status",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedClearingHouseCopy.status",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }