

























































import { Vue, Component } from 'vue-property-decorator';
import ClearingHousesCC from './lists-options-more/ClearingHousesCC.vue';
import EHRsystemsCC from './lists-options-more/EHRsystemsCC.vue';
import ModifiersCC from './lists-options-more/ModifiersCC.vue';
import DiagnosisCC from './lists-options-more/DiagnosisCC.vue';
import ContactRoleTypesCC from './lists-options-more/ContactRoleTypesCC.vue';
import POScodesCC from './lists-options-more/POScodesCC.vue';
import DrSpecialtiesCC from './lists-options-more/DrSpecialtiesCC.vue';
import InsurancePlanCC from './lists-options-more/InsurancePlanCC.vue';

import {
  BTab, BTabs, BCard,
} from 'bootstrap-vue'
import TabsComponent from "@/components/TabsComponent.vue";

@Component({
  components: {
    TabsComponent,
    ClearingHousesCC,
    EHRsystemsCC,
    ModifiersCC,
    DiagnosisCC,
    ContactRoleTypesCC,
    POScodesCC,
    DrSpecialtiesCC,
    InsurancePlanCC,
    BTab,
    BTabs,
    BCard,
  }
})
export default class ListsOptionsCC extends Vue {

  // DATA

  // COMPUTED

  // METHODS

  // MOUNTED
}
